.App {
    text-align: center;
}

.App-logo {
    height: 22vmin;
    pointer-events: none;
}

.App-header {
    background-color: #7434ec;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: rgb(255, 255, 255);
    padding-top: 0px;
    padding-bottom: 0px;
}

.MuiButton-root {
    border-radius: 20px;
}

.App-link {
    color: #61dafb;
}

@font-face {
    font-family: "Lato";
    src: local("Lato"), url("./fonts/Lato-Bold.ttf") format("truetype");
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}